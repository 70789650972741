import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { LOCALE_REPLACEMENT_FOR_HREF_LANG_CODE } from '@rsc/gatsby-localization-helpers';

const getHrefLang = locale =>
  LOCALE_REPLACEMENT_FOR_HREF_LANG_CODE?.[locale] || locale;

function Head({
  description = '',
  lang = 'en',
  meta = [],
  title = '',
  keywords = '',
  linkTags = [],
  children = null,
  scriptTags = [],
  noIndex = false,
  noFollow = false,
  hreflangs = [],
}) {
  let noIndexFollow = [];
  if (process.env.GATSBY_ROBOTS_INDEX === 'true') {
    if (noIndex) noIndexFollow.push('noindex');
    else noIndexFollow.push('index');

    if (noFollow) noIndexFollow.push('nofollow');
    else noIndexFollow.push('follow');
  } else {
    noIndexFollow = ['noindex', 'nofollow'];
  }

  const hreflangLinks = [];
  hreflangs.forEach(data => {
    hreflangLinks.push(
      <link
        rel="alternate"
        hrefLang={getHrefLang(data.lang)}
        href={data.url}
        key={data.url}
      />
    );
  });

  return (
    <Helmet
      htmlAttributes={{ lang: getHrefLang(lang) }}
      title={title}
      meta={[
        {
          name: 'robots',
          content: noIndexFollow?.length && noIndexFollow.join(','),
        },
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        },
        ...meta,
      ]}
    >
      {linkTags.map(linkTag => linkTag)}
      {hreflangLinks.map(link => link)}
      {children}
      {scriptTags.map(scriptTag => scriptTag)}
    </Helmet>
  );
}

Head.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  keywords: PropTypes.string,
  linkTags: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.node,
  scriptTags: PropTypes.arrayOf(PropTypes.shape({})),
  noIndex: PropTypes.bool,
  noFollow: PropTypes.bool,
  hreflangs: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Head;
