import React from 'react';
import Layout from '@components/layout';
import { getTranslationCache } from '@rsc/sanity-static-localization-provider-library';
import {
  InternationalContext,
  useInternationalHelper,
} from '@rsc/gatsby-localization-helpers';
import { uiComponentsLocalizationData } from '@rsc/static-translations';

const InternationalContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const mockTranslationCache = getTranslationCache(
    null,
    uiComponentsLocalizationData
  );

  const staticContentLocalizer = useInternationalHelper(
    'en-us',
    mockTranslationCache,
    uiComponentsLocalizationData
  );

  return (
    <InternationalContext.Provider value={staticContentLocalizer}>
      {children}
    </InternationalContext.Provider>
  );
};

function PageWrapper({ element }: { element: React.ReactElement }) {
  return (
    <InternationalContextWrapper>
      <Layout>{element}</Layout>
    </InternationalContextWrapper>
  );
}

export default PageWrapper;
