/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  LocalizedLink,
  getDebugLinkStyleIfInvalid,
} from '@rsc/gatsby-localization-helpers';

function LinkComponent({ children = null, ...props }) {
  const isInternalLink = /^\/(?!\/)/.test(props?.to);

  if (!isInternalLink)
    return (
      <a
        href={props?.to || props?.href}
        style={getDebugLinkStyleIfInvalid(props)}
        {...props}
      >
        {children}
      </a>
    );

  return props?.to && <LocalizedLink {...props}>{children}</LocalizedLink>;
}

LinkComponent.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node,
};

export default LinkComponent;
